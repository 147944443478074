import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, { retries: 3 });

const apiConfig = {
  GET_USER: "https://getuser-2fxcwf4fha-uc.a.run.app",
  GET_INVITE_CODES: "https://getinvitecodes-2fxcwf4fha-uc.a.run.app",
  SUBMIT_INVITE_CODE: "https://submitinvitecode-2fxcwf4fha-uc.a.run.app",
  TWITTER_AUTH: "https://twitterauth-2fxcwf4fha-uc.a.run.app",
  TWITTER_CALLBACK: "https://twittercallback-2fxcwf4fha-uc.a.run.app",
  GET_POINTS: "https://getpoints-2fxcwf4fha-uc.a.run.app",
  ADD_WALLET: "https://addwallet-2fxcwf4fha-uc.a.run.app",
  REMOVE_WALLET: "https://removewallet-2fxcwf4fha-uc.a.run.app",
  GET_WHITELISTED_WALLET_HOLDINGS:
    "https://getwhitelistedwalletholdingsv2-2fxcwf4fha-uc.a.run.app",
  GET_HASH_FOR_WALLET: "https://gethashforwallet-2fxcwf4fha-uc.a.run.app",
  GET_HASH_FOR_WALLET_VIA_ADD_WALLET_CODE:
    "https://gethashforwalletviaaddwalletcode-2fxcwf4fha-uc.a.run.app",
  GET_ADD_WALLET_CODE: "https://getaddwalletcode-2fxcwf4fha-uc.a.run.app",
  GET_ADD_WALLET_CODE_EXISTS:
    "https://getaddwalletcodeexists-2fxcwf4fha-uc.a.run.app",
  GET_USER_VIA_ADD_WALLET_CODE:
    "https://getuserviaaddwalletcode-2fxcwf4fha-uc.a.run.app",
  GET_USERS_WITH_POINTS: "https://getuserswithpoints-2fxcwf4fha-uc.a.run.app",
};

const getApiInstance = ({ bearerToken }) => {
  const instance = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (bearerToken) {
    instance.defaults.headers.common["Authorization"] = `Bearer ${bearerToken}`;
  }

  return instance;
};

// post to addWallet with chain, address. chain can be doge and btc
export const addWallet = async ({
  chain,
  address,
  signature,
  addWalletCode,
  pubKey,
}) => {
  if (!addWalletCode) {
    throw new Error("No addWalletCode found");
  }

  if (chain === "doge" && !pubKey) {
    throw new Error("Doge chain requires pubKey");
  }
  const socialApi = getApiInstance({});
  const res = await socialApi.post(apiConfig.ADD_WALLET, {
    chain,
    address,
    signature,
    addWalletCode,
    publicKey: pubKey,
  });
  return res.data;
};

// getHashForWallet
export const getHashForWallet = async ({ addWalletCode }) => {
  if (!addWalletCode) {
    throw new Error("No addWalletCode found");
  }

  const socialApi = getApiInstance({});
  const res = await socialApi.get(
    apiConfig.GET_HASH_FOR_WALLET_VIA_ADD_WALLET_CODE,
    {
      params: { addWalletCode },
    }
  );
  return res.data;
};

// getAddWalletCodeExists
export const getAddWalletCodeExists = async ({ getAddWalletCode }) => {
  const socialApi = getApiInstance({});
  const res = await socialApi.get(apiConfig.GET_ADD_WALLET_CODE_EXISTS, {
    params: { addWalletCode: getAddWalletCode },
  });
  return res.data;
};

// getUserViaAddWalletCode
export const getUserViaAddWalletCode = async ({ addWalletCode }) => {
  const socialApi = getApiInstance({});
  const res = await socialApi.get(apiConfig.GET_USER_VIA_ADD_WALLET_CODE, {
    params: { addWalletCode },
  });
  return res.data;
};

export const getUsersWithPoints = async ({ page }) => {
  const socialApi = getApiInstance({});
  const res = await socialApi.get(apiConfig.GET_USERS_WITH_POINTS, {
    params: { page },
  });
  return res.data;
};
