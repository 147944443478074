import React, { useCallback, useState, useRef, useEffect } from "react";
import cn from "classnames";

// Subcomponent for individual input
const PinInputField = ({
  disabled,
  value,
  index,
  handleChange,
  handleBackspace,
  handlePaste,
}) => {
  const inputRef = useRef(null);

  // Effect to focus input if needed
  useEffect(() => {
    if (value === "" && index === 0) {
      inputRef.current.focus();
    }
  }, [value, index]);

  // Handle key down to manage backspace
  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && !value) {
      handleBackspace(index);
    }
  };

  return (
    <input
      disabled={disabled}
      ref={inputRef}
      type="text"
      maxLength="1"
      value={value}
      onChange={(e) => handleChange(e, index)}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
      className="block w-[32px] h-[62px] sm:size-[62px] text-center border border-primary-200 rounded-md text-lg [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:border-2 focus:border-primary-500 outline-none disabled:opacity-50 disabled:pointer-events-none"
      placeholder="⚬"
      data-hs-pin-input-item={true}
      autoComplete="off"
    />
  );
};

// Main component using the subcomponent
export const CodeInput = ({ className, disabled, length = 8, onChange }) => {
  const [codes, setCodes] = useState(Array(length).fill(""));
  const inputRefs = useRef(new Array(length).fill(null));

  const handleChange = useCallback(
    (e, index) => {
      const newValue = e.target.value.toUpperCase(); // Convert input value to uppercase
      const newCodes = [...codes];
      newCodes[index] = newValue;
      setCodes(newCodes);

      // Move focus forward on input
      if (newValue && index < length - 1) {
        document
          .querySelector(
            `input[data-hs-pin-input-item="true"]:nth-child(${index + 2})`
          )
          ?.focus();
      }
      onChange(newCodes.join(""));
    },
    [codes, length, onChange]
  );

  const handleBackspace = useCallback((index) => {
    if (index > 0) {
      document
        .querySelector(
          `input[data-hs-pin-input-item="true"]:nth-child(${index})`
        )
        ?.focus();
    }
  }, []);

  const handlePaste = useCallback(
    (e) => {
      e.preventDefault();
      const pasteData = e.clipboardData.getData("text").slice(0, length);
      const newCodes = pasteData.split("");
      if (newCodes.length !== length) {
        return;
      }

      setCodes(newCodes.fill("", newCodes.length, length)); // Fill remaining with empty strings if less than length

      const nextIndex = newCodes.length < length ? newCodes.length : length - 1;
      inputRefs.current[nextIndex]?.focus();
      onChange(newCodes.join(""));
    },
    [length, onChange]
  );

  return (
    <div
      className={cn("flex space-x-3 overflow-scroll", className)}
      data-hs-pin-input=""
    >
      {codes.map((code, index) => (
        <PinInputField
          disabled={disabled}
          ref={(el) => (inputRefs.current[index] = el)}
          key={index}
          value={code}
          index={index}
          handleChange={handleChange}
          handleBackspace={handleBackspace}
          handlePaste={handlePaste}
        />
      ))}
    </div>
  );
};
