import { Spinner } from "./Spinner";
import { CodeInput } from "./CodeInput";
import { WalletConnectBox } from "./WalletConnectBox";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { getAddWalletCodeExists } from "../api/backend";
import { BackendConnectionContext } from "../provider/BackendConnectionProvider";

import btcIcon from "./bitcoin.png";
import dogeIcon from "./doge.png";
import { Button } from "./Button";

const shortenAddress = (address, chars = 4) => {
  return `${address.substring(0, chars + 2)}…${address.substring(
    address.length - chars
  )}`;
};

export const AddWallet = () => {
  const [isSuccessfullyConnected, setIsSuccessfullyConnected] = useState(false);
  const [addWalletCodeLoading, setAddWalletCodeLoading] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [connectedWallets, setConnectedWallets] = useState({
    btc: [],
    doge: [],
  });

  const { addWalletCode, setAddWalletCode, user, setConnected, connected } =
    useContext(BackendConnectionContext);

  const checkAddWalletCodeAndSetWallets = useCallback(
    async (code) => {
      setAddWalletCodeLoading(true);

      try {
        const res = await getAddWalletCodeExists({
          getAddWalletCode: code,
        });
        if (res.exists && res.exists === true) {
          setConnectedWallets(res.wallets);
          setConnected(true);
          setIsSuccessfullyConnected(true);
          setAddWalletCode(code);
          setInvalidCode(false);
        }

        if (res?.exists === false) {
          setInvalidCode(true);
        }
      } catch (error) {
        setInvalidCode(true);
      }

      setAddWalletCodeLoading(false);
    },
    [setAddWalletCode, setConnected]
  );

  const onchangeAddWalletCode = useCallback(
    async (e) => {
      // if we reach a length of 8 we block change until we called the api

      const uppercaseCode = e.toUpperCase().trim();

      setAddWalletCodeLoading(true);
      if (uppercaseCode.length >= 8) {
        await checkAddWalletCodeAndSetWallets(uppercaseCode);
      }
      setAddWalletCodeLoading(false);
      console.log(e);
    },
    [checkAddWalletCodeAndSetWallets]
  );

  useEffect(() => {
    if (!connected && isSuccessfullyConnected) {
      window.location.reload();
    }
  }, [connected, isSuccessfullyConnected, setAddWalletCode]);

  const resetCode = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <div className="flex flex-col space-y-4 pt-4 pb-4">
      <h2 className="flex flex-row space-x-4 text-2xl font-bold items-center">
        <span>Connect via Code</span>
        <span>{addWalletCodeLoading ? <Spinner small /> : null}</span>
      </h2>
      <CodeInput
        className="mt-4"
        disabled={addWalletCodeLoading}
        onChange={onchangeAddWalletCode}
      />
      {invalidCode && (
        <div className="flex flex-col space-y-4 max-w-[580px]">
          <div className="text-text-error mt-4">
            <strong>Invalid code</strong>. Codes expire after 5 minutes.
          </div>
          <Button variant="secondary" onClick={resetCode}>
            Reset
          </Button>
        </div>
      )}
      {connected && (
        <WalletConnectBox
          className="mt-4"
          addWalletCode={addWalletCode}
          connectedWallets={connectedWallets}
          afterConnect={async () =>
            checkAddWalletCodeAndSetWallets(addWalletCode)
          }
        />
      )}
      {connected && user && (
        <div className="flex flex-col space-y-2 max-w-[580px]">
          <h2 className="text-2xl font-bold">Connected Wallets</h2>
          {user.wallets?.btc?.map((wallet) => (
            <div className="flex flex-row space-x-2 bg-transparent">
              <div className="relative flex flex-1 flex-row items-center justify-start bg-background-primary/50 backdrop-blur-sm border border-background-primary/20 shadow-sm space-x-2 px-2 rounded-lg">
                <img src={btcIcon} alt="btc" className="w-8 h-8" />
                <p className="flex items-center justify-start font-bold text-text-primary text-md min-w-0 min-h-12">
                  {shortenAddress(wallet, 5)}
                </p>
              </div>
            </div>
          ))}
          {user.wallets?.doge?.map((wallet) => (
            <div className="flex flex-row space-x-2 bg-transparent">
              <div className="relative flex flex-1 flex-row items-center justify-start bg-background-primary/50 backdrop-blur-sm border border-background-primary/20 shadow-sm space-x-2 px-2 rounded-lg">
                <img src={dogeIcon} alt="doge" className="w-8 h-8" />
                <p className="flex items-center justify-start font-bold text-text-primary text-md min-w-0 min-h-12">
                  {shortenAddress(wallet, 5)}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
