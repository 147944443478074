import React from "react";
import cn from "classnames";
import FlipNumbers from "react-flip-numbers";
import { useLeaderboard } from "../provider/LeaderBoardProvider";
import { Spinner } from "./Spinner";
import { Button } from "./Button";
import { useContext } from "react";
import { BackendConnectionContext } from "../provider/BackendConnectionProvider";

const truncateName = (name) => {
  if (name?.length > 20) {
    return name.slice(0, 20) + "...";
  } else {
    return name;
  }
};

const renderNumber = (number) => {
  return number !== -1 ? number : "ㅤ"; // use invisible text char to keep height
};

const formatNumber = (number) => {
  return number.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const LeaderBoardRow = ({
  username,
  points,
  multiplier,
  rank,
  className,
  isHighlighted,
  isLoading,
  key,
}) => {
  return (
    <div
      className={cn(
        "flex flex-row p-4 space-x-4 font-bold text-xs border border-background-primary/20 rounded-lg",
        className,
        {
          "bg-background-highlight text-text-light": isHighlighted,
          "bg-background-primary/60 text-text-primary": !isHighlighted,
          "animate-pulse": isLoading,
        }
      )}
      key={key}
    >
      <span className="w-4 flex flex-col items-center">
        {renderNumber(rank)}
      </span>
      <div className="flex flex-row flex-1 space-x-2 justify-between text-left">
        <div className="flex flex-1 min-w-0">
          <span className="truncate">
            {isLoading ? "Loading…" : truncateName(username)}
          </span>
        </div>
        <div className="flex flex-row flex-1 space-x-2 text-right">
          <span className="flex flex-col flex-1">
            {!isLoading && points > -1 ? (
              isHighlighted ? (
                <FlipNumbers
                  height={10}
                  width={10}
                  color={isHighlighted ? "white" : "#0f172a"}
                  background="transparent"
                  play
                  perspective={100}
                  numbers={formatNumber(points)}
                  duration={0.5}
                />
              ) : (
                formatNumber(points)
              )
            ) : (
              renderNumber(points)
            )}
          </span>
          <span className="flex flex-col flex-1">
            {multiplier === 0 ? "-" : `${renderNumber(multiplier)}x`}
          </span>
        </div>
      </div>
    </div>
  );
};

export const Leaderboard = () => {
  const { allEntries, isAtPaginationEnd, isFetching, goToNextPage } =
    useLeaderboard();

  const { user, connected } = useContext(BackendConnectionContext);

  return (
    <>
      <div className="flex h-full overflow-y-scroll flex-1 flex-col min-w-0 pt-4 pb-2 px-2 bg-background-primary/50 backdrop-blur-sm border border-background-primary/20 shadow-sm">
        <h2 className="text-lg text-center font-bold text-text-highlight">
          LEADERBOARD
        </h2>
        <div className="flex flex-col space-y-2 pt-4">
          {/* LeaderBoardHeader */}
          <div className="flex flex-row space-x-4 font-black text-xs text-text-primary py-2 px-4">
            <span className="w-4 flex flex-col items-center">#</span>
            <div className="flex flex-row flex-1 space-x-2 justify-between text-left">
              <span className="flex flex-col flex-1">Player</span>
              <div className="flex flex-row flex-1 space-x-2 text-right">
                <span className="flex flex-col flex-1">Pts</span>
                <span className="flex flex-col flex-1">Mult</span>
              </div>
            </div>
          </div>
          {connected && (
            <div className="flex flex-col space-y-2 p-2">
              <LeaderBoardRow
                key={user?.name}
                username={`${user?.name} (you)`}
                points={user?.points || -1}
                multiplier={user?.multiplier || -1}
                rank={user?.rank || -1}
                isHighlighted
                isLoading={!user}
              />
            </div>
          )}

          <div className="flex flex-col pb-4 w-full px-4">
            <div className="w-full h-[0.5px] bg-base-300" />
          </div>

          {allEntries?.map((player) => (
            <LeaderBoardRow {...player} key={player?.name} />
          ))}

          {isFetching && (
            <div className="flex flex-col items-center justify-center">
              <Spinner small />
            </div>
          )}

          {!isAtPaginationEnd && !isFetching && (
            <Button
              disabled={isFetching}
              size="xs"
              variant="light"
              onClick={goToNextPage}
            >
              More
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
