import React, {
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
} from "react";
import { getUsersWithPoints } from "../api/backend";
import { useInterval } from "usehooks-ts";

export const LeaderboardContext = createContext();

export const LeaderboardProvider = ({ children }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [leaderboard, setLeaderboard] = useState({});
  const [page, setPage] = useState(0);
  const [isAtPaginationEnd, setIsAtPaginationEnd] = useState(false);

  // method to calculate locally how much points the user has earned since the last update
  const updateLeaderboardLocally = useCallback(() => {
    setLeaderboard((prevLeaderboard) => {
      const newLeaderboard = { ...prevLeaderboard };
      Object.values(newLeaderboard).forEach((page) => {
        page.forEach((user) => {
          const minutes = (Date.now() - user.timestamp) / 60000;
          const addPoints = minutes * user.multiplier * 0.1;
          user.points = user.initialPoints + addPoints;
        });
      });
      return newLeaderboard;
    });
  }, []);

  const fetchLeaderboard = useCallback(async () => {
    setIsFetching(true);
    const res = await getUsersWithPoints({ page: 0 });
    setLeaderboard({
      0: res.map((el) => ({
        ...el,
        initialPoints: el.points,
      })),
    });
    setIsFetching(false);
    updateLeaderboardLocally();
  }, [updateLeaderboardLocally]);

  useEffect(() => {
    fetchLeaderboard();
  }, [fetchLeaderboard]);

  const goToNextPage = useCallback(async () => {
    const nextPage = page + 1;
    if (!leaderboard[nextPage]) {
      setIsFetching(true);
      const res = await getUsersWithPoints({ page: nextPage });
      if (res.length < 100) {
        setIsAtPaginationEnd(true);
      }
      setLeaderboard((prevLeaderboard) => ({
        ...prevLeaderboard,
        [nextPage]: res.map((el) => ({
          ...el,
          initialPoints: el.points,
        })),
      }));
      setIsFetching(false);
    }
    setPage(nextPage);
  }, [page, leaderboard]);

  const goToPreviousPage = useCallback(() => {
    const previousPage = page - 1;
    if (previousPage >= 0) {
      setPage(previousPage);
    }
  }, [page]);

  useInterval(updateLeaderboardLocally, isFetching ? null : 3000);

  // Flatten the pages to a single array
  const allEntries = Object.values(leaderboard).flat();

  return (
    <LeaderboardContext.Provider
      value={{
        isFetching,
        isAtPaginationEnd,
        goToPreviousPage,
        goToNextPage,
        allEntries,
      }}
    >
      {children}
    </LeaderboardContext.Provider>
  );
};

export const useLeaderboard = () => {
  return useContext(LeaderboardContext);
};
