import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BackendConnectionProvider } from "./provider/BackendConnectionProvider";
import { LeaderboardProvider } from "./provider/LeaderBoardProvider";
import { PageError } from "./components/PageError";
import { Layout } from "./components/Layout";
import { AddWallet } from "./components/AddWallet";
import { Leaderboard } from "./components/LeaderBoard";
import { Terms } from "./components/Terms";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />} errorElement={<PageError />}>
      <Route index element={<Navigate replace to="/addWallet" />} />
      <Route path="addwallet" element={<AddWallet />} />
      {/* <Route path="leaderboard" element={<Leaderboard />} /> */}
      <Route path="Terms" element={<Terms />} />
      <Route path="*" element={<PageError />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LeaderboardProvider>
      <BackendConnectionProvider>
        <RouterProvider router={router} />
      </BackendConnectionProvider>
    </LeaderboardProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
