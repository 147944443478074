import React, { useCallback, useMemo, useState, useContext } from "react";

import Wallet, { AddressPurpose } from "sats-connect";
import { getAccessToken } from "@privy-io/react-auth";
import { Button } from "./Button";
import { addWallet, getHashForWallet } from "../api/backend";
import cn from "classnames";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { BackendConnectionContext } from "../provider/BackendConnectionProvider";
import { Spinner } from "./Spinner";

const noop = () => {};

export const WalletConnectBox = ({ afterConnect, className }) => {
  const [chain, setChain] = useState("btc");
  const onChangeChain = useCallback((event) => {
    setChain(event.target.value);
  }, []);

  const { addWalletCode } = useContext(BackendConnectionContext);

  const [error, setError] = useState();
  const [isDogeLabsWalletMissing, setIsDogeLabsWalletMissing] = useState(false);

  const [isAddWalletLoading, setIsAddWalletLoading] = useState(false);
  const handleAddWallet = useCallback(async () => {
    setIsAddWalletLoading(true);
    // get hash nonce from the api
    const nonce = await getHashForWallet({ addWalletCode });

    const message =
      "I am signing my one-time nonce for access to the relics app. nonce: " +
      nonce.nonce;

    let address = undefined;
    let signedMessage = undefined;
    let pubKey = undefined;

    if (chain === "doge") {
      // do doge stuff
      if (!window.dogeLabs) {
        setIsDogeLabsWalletMissing(true);
        setIsAddWalletLoading(false);
        return;
      }

      signedMessage = await window.dogeLabs.signMessage(message);
      const accounts = await window.dogeLabs.getAccounts();
      pubKey = await window.dogeLabs.getPublicKey(accounts[0]);

      address = accounts[0];
    } else {
      const response = await Wallet.request("getAccounts", {
        purposes: [AddressPurpose.Ordinals],
        message: "Please connect your ordinals wallet",
      });

      if (response.status !== "success") {
        console.error("error getting accounts", response.error);
        setIsAddWalletLoading(false);
        return;
      }

      const ordinalAddress = response.result.find(
        (account) => account.purpose === AddressPurpose.Ordinals
      );

      if (!ordinalAddress) {
        console.error("no payment or ordinal address found");
        setIsAddWalletLoading(false);
        return;
      }
      // request a signature for the ordinal address
      const signatureResponse = await Wallet.request("signMessage", {
        address: ordinalAddress?.address,
        message,
      });

      signedMessage = signatureResponse.result.signature;

      console.log("signatureResponse", signatureResponse);
      if (signatureResponse.status !== "success") {
        console.error("error signing message", signatureResponse.error);
        setIsAddWalletLoading(false);
        return;
      }

      if (!addWalletCode) {
        console.error("no addWalletCode");
        setIsAddWalletLoading(false);
        return;
      }

      address = ordinalAddress.address;
    }

    try {
      await addWallet({
        getAccessToken,
        chain,
        address,
        signature: signedMessage,
        addWalletCode,
        pubKey: pubKey || undefined,
      });
    } catch (error) {
      console.error("error adding wallet", error);

      setError(error.response.data);
    } finally {
      setIsAddWalletLoading(false);
    }

    if (afterConnect) {
      await afterConnect();
    }
  }, [addWalletCode, afterConnect, chain]);

  const submitAddressButtonState = useMemo(() => {
    return {
      disabled: false,
      text: isDogeLabsWalletMissing ? (
        <a
          href="https://chromewebstore.google.com/detail/doge-labs-wallet/jiepnaheligkibgcjgjepjfppgbcghmp"
          target="_blank"
          rel="noreferrer"
        >
          <div className="flex flex-row space-x-4 justify-center items-center">
            <span>Install Doge Labs Wallet</span>
            <ArrowUpRightIcon className="h-4 w-4" />
          </div>
        </a>
      ) : isAddWalletLoading ? (
        <div className="flex flex-row space-x-4 justify-center items-center">
          <span>Loading</span>
          <span>
            <Spinner small />
          </span>
        </div>
      ) : (
        "Connect a wallet"
      ),
    };
  }, [isAddWalletLoading, isDogeLabsWalletMissing]);

  return (
    <div
      className={cn(
        "flex flex-col backdrop-blur-xl bg-background-primary/60",
        className
      )}
    >
      <h2 className="text-2xl font-bold">Choose Chain</h2>

      {/** Actions */}
      <div className="flex flex-col space-y-4 w-full max-w-[580px]">
        {true && <div className="flex flex-row space-x-2 bg-transparent"></div>}
        {/* select for the chain. can be btc or doge */}
        <div className="flex flex-row space-x-2 bg-transparent">
          <div className="relative flex flex-1 flex-row items-center bg-background-primary/50 backdrop-blur-sm border border-background-primary/20 shadow-sm">
            <select
              className="bg-background-toxic/5 rounded-sm font-bold text-text-primary text-md min-w-0 px-4 outline-none focus:ring-0 min-h-12 w-full uppercase"
              onChange={onChangeChain}
              value={chain}
            >
              <option value="doge">DOGE</option>
              <option value="btc">BTC</option>
            </select>
          </div>
        </div>
        <Button
          variant={isDogeLabsWalletMissing ? "secondary" : "primary"}
          disabled={submitAddressButtonState.disabled || isAddWalletLoading}
          onClick={isDogeLabsWalletMissing ? noop : handleAddWallet}
          sound="click"
        >
          {submitAddressButtonState.text}
        </Button>
        {error && (
          <span className="text-sm text-text-error">Error: {error || ""}</span>
        )}
      </div>
    </div>
  );
};
