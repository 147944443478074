import { motion } from "framer-motion";
import React from "react";

export const Spinner = ({ small = false }) => {
  const size = small ? "w-5 h-5" : "w-10 h-10";
  const innerSize = small ? "w-2.5 h-2.5" : "w-5 h-5";
  const scaleVariants = small ? [1, 1.2, 1.2, 1, 1] : [1, 1.5, 1.5, 1, 1];

  return (
    <motion.div
      className={`flex flex-col justify-center items-center ${size} bg-background-highlight/40 border border-background-highlight/20`}
      animate={{
        scale: scaleVariants,
        rotate: [0, 0, 180, 180, 0],
        borderRadius: ["5%", "5%", "50%", "50%", "5%"],
      }}
      transition={{
        duration: 2,
        ease: "easeInOut",
        times: [0, 0.2, 0.5, 0.8, 1],
        repeat: Infinity,
        repeatDelay: 1,
      }}
    >
      <motion.div
        className={`bg-background-highlight/80 ${innerSize}`}
        animate={{
          scale: scaleVariants,
          rotate: [0, 0, 180, 180, 0],
          borderRadius: ["5%", "5%", "50%", "50%", "5%"],
        }}
        transition={{
          duration: 2,
          ease: "easeInOut",
          times: [0, 0.2, 0.5, 0.8, 1],
          repeat: Infinity,
          repeatDelay: 1,
        }}
      />
    </motion.div>
  );
};
