import { useRouteError } from "react-router-dom";

import eye from "./eye.gif";

export const PageError = () => {
  const error = useRouteError();

  return (
    <div className="flex flex-1 flex-col py-safe-or-4">
      <div className="flex flex-1 flex-col justify-center items-center">
        <img
          alt="Error"
          src={eye}
          className="w-3/4 aspect-square rounded-full"
        />
        <div className="flex flex-col text-center space-y-2 p-4">
          <h1 className="font-black text-2xl text-text-primary">
            Oops, Something Went Wrong!
          </h1>
          <p className="text-sm text-text-secondary">
            It looks like an unexpected error occurred. Our team is already
            working on a fix. Please hang in there, and we'll have things back
            to normal shortly.
          </p>
          <i className="text-xs text-text-error">
            {error.statusText || error.message}
          </i>
        </div>
      </div>
    </div>
  );
};
