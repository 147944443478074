import { useState, createContext, useEffect } from "react";
import { getUserViaAddWalletCode } from "../api/backend";
import { useInterval } from "usehooks-ts";

// contect
export const BackendConnectionContext = createContext();

export const BackendConnectionProvider = ({ children }) => {
  // user state
  const [user, setUser] = useState();
  const [addWalletCode, setAddWalletCode] = useState("");
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (addWalletCode.length < 8) {
      return;
    }
    getUserViaAddWalletCode({ addWalletCode })
      .then((res) => {
        setUser(res);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [addWalletCode]);

  // use useIntervall to check every 10 secs if the addWalletCode is still valid
  useInterval(() => {
    if (addWalletCode.length < 8) {
      setConnected(false);
    }
    getUserViaAddWalletCode({ addWalletCode })
      .then((res) => {
        setUser(res);
      })
      .catch((error) => {
        setConnected(false);
      });
  }, 10_000);

  return (
    <BackendConnectionContext.Provider
      value={{ user, setAddWalletCode, addWalletCode, setConnected, connected }}
    >
      {children}
    </BackendConnectionContext.Provider>
  );
};
