import React from "react";

export const Terms = () => (
  <>
    <div className="flex h-full overflow-y-scroll flex-1 flex-col min-w-0 pt-4 pb-2 px-2 bg-background-primary/50 backdrop-blur-sm border border-background-primary/20 shadow-sm">
      <h2 className="text-lg text-center font-bold text-text-highlight">
        TERMS
      </h2>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col">
          <h3 className="text-md text-left font-bold text-text-highlight">
            1) GENERAL RISKS
          </h3>
          <p className="text-sm">
            Trading cryptocurrencies carries a high level of risk, and may not
            be suitable for all investors. Before deciding to trade
            cryptocurrency you should carefully consider your investment
            objectives, level of experience, and risk appetite. The possibility
            exists that you could sustain a loss of some or all of your initial
            investment and therefore you should not invest money that you cannot
            afford to lose. You should be aware of all the risks associated with
            cryptocurrency trading, and seek advice from an independent
            financial advisor. Token sales, Airdrops, ICO's, IEO's, STO's, NFT
            mints, Ordinal sales and any other form of offering will not
            guarantee a return on your investment.
            <br />
            <br />
            Any opinions, news, research, analyses, prices, or other information
            contained on this website is provided as general market commentary,
            and does not constitute investment advice.
            <br />
            <br />
            Relics will not accept liability for any loss or damage, including
            without limitation to, any loss of profit, which may arise directly
            or indirectly from use of or reliance on such information. All
            opinions expressed on this site are owned by the respective writer
            and should never be considered as advice in any form. Relics makes
            no representation or warranties as to the accuracy and or timelines
            of the information contained herein. A qualified professional should
            be consulted before making any financial decisions.
          </p>
        </div>
        <div className="flex flex-col">
          <h3 className="text-md text-left font-bold text-text-highlight">
            2) POINTS
          </h3>
          <p className="text-sm">
            Points collected on this platform are not tokens and do not hold any
            intrinsic value. Engaging in the trading of points, especially
            through over-the-counter (OTC) methods, may result in
            disqualification from any future airdrops. Purchasing points does
            not guarantee eligibility for an airdrop. Participation in the
            points collection does not ensure that an airdrop will occur. If we
            do not succeed in developing the protocol, the points you have
            accumulated will have no value.
            <br />
            <br />
            We reserve the right to ban any user from participating in the
            airdrop if we suspect attempts to farm the airdrop using multiple
            accounts. The decision to ban a user is at the sole discretion of
            the Relics team, particularly in cases of suspected sybil attacks.
            Additionally, the amount of points you have or the rate at which
            points are awarded can be adjusted at any time without notice.
            <br />
            <br />
            Multipliers, which can influence the rate at which points are
            earned, can be introduced or removed at any time. You should not
            purchase any NFT collection or other asset solely for the purpose of
            obtaining a multiplier. The presence of a multiplier does not
            guarantee increased value or airdrop eligibility.
            <br />
            <br />
            By participating in this points collection system, you acknowledge
            and accept these conditions.
          </p>
        </div>
      </div>
    </div>
  </>
);
